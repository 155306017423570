import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, map, Observable, OperatorFunction } from 'rxjs';
import { FullUserModel, MigrationStatus } from '../../assets/models/user/User.model';
import { UserApiService } from './user-api.service';
import { User } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  #authService: AuthService = inject(AuthService);
  #userApiService: UserApiService = inject(UserApiService);

  #user$: BehaviorSubject<FullUserModel | null> = new BehaviorSubject<FullUserModel | null>(null);

  getUser$(): Observable<FullUserModel | null> {
    return this.#user$.asObservable();
  }

  getUserUid$(): Observable<string> {
    return this.getUser$().pipe(
      map((fullUser: FullUserModel | null) => fullUser?.user?.uid),
      filter((uid: string) => !!uid) as OperatorFunction<string | undefined, string>,
    );
  }

  setUser(userFromAuth: User | null) {
    if (userFromAuth?.uid) {
      this.#userApiService.getFullBeneficiaryFromAPI$(userFromAuth.uid)
        .pipe(
          tap((user: FullUserModel) => this.#user$.next(user)),
          tap((user: FullUserModel) => this.#authService.setConnected(user, false)),
        ).subscribe();
    } else {
      this.#authService.setDisconnected();
    }
  }

  refreshUser$(): Observable<FullUserModel> {
    return this.getUserUid$().pipe(
      switchMap((userUid: string) => {
        return this.#userApiService.getFullBeneficiaryFromAPI$(userUid);
      }),
      tap((user: FullUserModel) => this.#user$.next(user)),
    );
  }
}
