import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {

  constructor(
    private modal: NzModalService,
  ) {
  }

  manage(err: any): Observable<Error> {
    return of(err)
      .pipe(
        switchMap(() => {
          let message: string = `${err.name} ${err.message}`;
          if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password' || 'auth/beneficiary-not-found') {
            message = `Impossible de se connecter, votre email ou votre mot de passe est invalide.`;
          }
          if (err.code === 'reset-password/no-match') {
            message = `Les deux mots de passe doivent être identiques`;
          }
          return this.modal.error({
            nzTitle: 'Attention',
            nzOkText: 'ok',
            nzContent: message,
          }).afterClose;
        }),
      );
  }
}
