import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallerService {
  private functions: Functions = inject(Functions);

  call<T, R>(functionName: string, args: T): Observable<R> {
    const functionToCall = httpsCallable<T, R>(this.functions, functionName);
    return from(functionToCall(args)).pipe(map((res: HttpsCallableResult<R>) => res.data));
  }
}
