import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  User,
} from '@angular/fire/auth';

import { Router } from '@angular/router';
import firebase from 'firebase/compat';
import { BehaviorSubject, from, map, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FullUserModel } from 'src/assets/models/user/User.model';
import { GlobalAuthStateModel } from '../../assets/models/globalAuthState.model';
import { VerifyEmailRequest } from '../../assets/requests/users/VerifyEmail.request';
import { InfoResponse } from '../../assets/responses/info.response';
import { environment } from '../../environments/environment';
import { CallerService } from './caller.service';
import { ErrorsService } from './errors.service';
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import { IdTokenResult, UserCredential } from '@firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  globalAuthState$: BehaviorSubject<GlobalAuthStateModel> = new BehaviorSubject<GlobalAuthStateModel>({
    isConnected: false,
  });

  #fbAuth: Auth = inject(Auth);
  #router: Router = inject(Router);
  #errorsService: ErrorsService = inject(ErrorsService);
  #caller: CallerService = inject(CallerService);
  #authStateFromFirebase$: Observable<User | null> = authState(this.#fbAuth);
  #domainForEmail: string = environment.name === 'local' ? 'http://localhost:4200/auth/login-progress' : '';

  createAccount$(email: string, password: string) {
    return from(createUserWithEmailAndPassword(this.#fbAuth, email, password))
      .pipe(
        catchError(err => this.#errorsService.manage(err)),
      );
  }

  sendLoginLink$(email: string): Observable<Error | void> {
    const actionCodeSettings: ActionCodeSettings = {
      url: this.#domainForEmail,
      handleCodeInApp: true,
    };

    return from(sendSignInLinkToEmail(this.#fbAuth, email, actionCodeSettings))
      .pipe(
        tap(() => window.localStorage.setItem('emailForSignIn', email)),
        catchError(err => this.#errorsService.manage(err)),
      );
  }

  checkLinkValue(href: string): boolean {
    return isSignInWithEmailLink(this.#fbAuth, href);
  }

  isUserBeneficiary(user: User): Observable<boolean> {
    return from(user.getIdTokenResult())
      .pipe(
        map((idToken: IdTokenResult) => {
          const { claims }: IdTokenResult = idToken;
          const roles: string[] = Array.isArray(claims['roles']) ? claims['roles'] : [];
          return roles.includes('BENEFICIARY');
        }),
        catchError((err) => {
          this.#errorsService.manage(err);
          return of(false);
        }),
      );
  }

  login$(email: string, password: string): Observable<Error | UserCredential> {
    return from(signInWithEmailAndPassword(this.#fbAuth, email, password)).pipe(
        switchMap((user: UserCredential) => {
          return this.isUserBeneficiary(user.user).pipe(
            map((isBeneficiary: boolean) => {
              if (!isBeneficiary) {
                throwError(() => new Error('auth/beneficiary-not-found'));
                this.logout$().subscribe();
              }
              return (user);
            }),
          )
        }),
        catchError(err => this.#errorsService.manage(err)));
  }

  logout$(): Observable<null | boolean> {
    return from(this.#fbAuth.signOut())
      .pipe(
        tap(() => this.setDisconnected()),
        switchMap(() => this.#router.navigate(['/auth/login'])),
        catchError(err => of(err)
          .pipe(
            tap((x) => alert(x)),
          )),
      );
  }

  firebaseSignInWithCustomToken$(customToken: string): Observable<any> {
    return from(signInWithCustomToken(this.#fbAuth, customToken))
      .pipe(catchError(err => this.#errorsService.manage(err)));
  }

  authStateFromFirebase$(): Observable<User | null> {
    return this.#authStateFromFirebase$;
  }

  globalAuthState(): Observable<GlobalAuthStateModel> {
    return this.globalAuthState$.asObservable();
  }

  verifyEmail$(email: string): Observable<InfoResponse<boolean>> {
    return this.#caller.call<VerifyEmailRequest, InfoResponse<boolean>>('USERS-VerifyEmail', { email });
  }

  setConnected(user: FullUserModel, isAdmin: boolean) {
    this.globalAuthState$.next({
      isConnected: true,
    });
  }

  setDisconnected() {
    this.globalAuthState$.next({
      isConnected: false,
    });
  }
}

