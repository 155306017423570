import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { FullUserModel, UserModel } from '../../assets/models/user/User.model';
import { CallerService } from './caller.service';
import { GetFullBeneficiaryOnCallRequest } from '../../assets/requests/users/GetFullBeneficiaryOnCall.request';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {

  #callerService: CallerService = inject(CallerService);
  #firestore: Firestore = inject(Firestore);

  getFullBeneficiaryFromAPI$(userUid: string): Observable<FullUserModel> {
    return this.#callerService
      .call<GetFullBeneficiaryOnCallRequest, FullUserModel>
      ('USERS-getFullBeneficiary_onCall', { userUid });
  }

  setIsAccountValidated$(userUid: string): Observable<void> {
    const user = doc(this.#firestore, 'BENEFICIARIES', userUid);
    return from(setDoc(user, { isAccountValidated: true }, { merge: true }));
  }

  updateUser(userUid: string, values: Partial<UserModel>) {
    const user = doc(this.#firestore, 'USERS', userUid);
    return from(setDoc(user, values, { merge: true }));
  }
}
