export const environment = {
  name:'dev',
  firebase: {
    apiKey: 'AIzaSyDtppBSqxsKnFEMF4-vcRihE764L6sIeCA',
    authDomain: 'dev-open-eat.firebaseapp.com',
    projectId: 'dev-open-eat',
    storageBucket: 'dev-open-eat.appspot.com',
    messagingSenderId: '992794764960',
    appId: '1:992794764960:web:4ca8912d4ea014fe6d0bc5',
    measurementId: 'G-2S2S9W1QLG',
  },
  recaptchaEnterpriseSiteKey: '6Lfue04pAAAAAH3jDWne0LElo5ar8iuLC65Fj5Ui',
};
